
import { React, useEffect, useState } from 'react'
import data from '../../assets/data/portfolioData'
import Modal from './Modal'
// import { collection } from 'firebase/firestore'
// import { db } from '../../firebaseConfig'



const Portfolio = () => {
  const [nextItems, setNextItem] = useState(6)
  const [portfolios, SetPortfolios] = useState(data)
  const [selectTab, setSelectTab] = useState("all")
  const [ShowModal, setShowModal] = useState(false)
  const [activeID, setActiveID] = useState(null)

  const loadMoreHandler = () => {
    setNextItem(prev => prev + 3)
  }

  const ShowModalHandler = id => {
    setShowModal(true)
    setActiveID(id)
  }

  useEffect(() => {
    if (selectTab === 'all') {
      SetPortfolios(data)
    }

    if (selectTab === 'Vanilla JS') {
      const filteredData = data.filter(items => items.category === 'Vanilla JS')
      SetPortfolios(filteredData)
    }

    if (selectTab === 'React JS') {
      const filteredData = data.filter(items => items.category === 'React JS')
      SetPortfolios(filteredData)
    }

    if (selectTab === 'Full-Stack') {
      const filteredData = data.filter(items => items.category === 'Full-Stack')
      SetPortfolios(filteredData)
    }

  }, [selectTab])


  // useEffect(() => {
  //   const fetch = async () => {
  //     try {
  //       const listingRef = collection(db, 'listing')

  //       const q = query(listingRef)

  //       fir
  //     } catch (error) {

  //     }
  //   }
  // })

  return (
    <section id='portfolio'>
      <div className='container'>
        <div className='flex items-center justify-between flex-wrap'>
          <div className='mb-7 sm:mb-0'>
            <h3 className='text-headingColor text-[2rem] font-[700]'>My Recent Projects</h3>
          </div>

          <div className='flex gap-3'>
            <button onClick={() => setSelectTab('all')} className='text-smallTextColor border border-solid border-smallTextColor py-2 px-4 rounded-[8px]'>All</button>
            <button onClick={() => setSelectTab('Vanilla JS')} className='text-smallTextColor border border-solid border-smallTextColor py-2 px-4 rounded-[8px]'>Vanilla JS</button>
            <button onClick={() => setSelectTab('React JS')} className='text-smallTextColor border border-solid border-smallTextColor py-2 px-4 rounded-[8px]'>React JS</button>
            <button onClick={() => setSelectTab('Full-Stack')} className='text-smallTextColor border border-solid border-smallTextColor py-2 px-4 rounded-[8px]'>Full-Stack</button>

          </div>

          <div className='flex items-center gap-4 flex-wrap mt-12'>
            {portfolios.slice(0, nextItems).map((Portfolio, index) => (
              <div key={index} data-aos="fade-zoom-in" data-aos-delay='90' data-aos-duration="1000" className='group max-w-full sm:w-[48.5%] md:w-[31.8%] lg:w-[32.2%] relative z-[1]'>
                <figure>
                  <img className='rounded-[8px]' src={Portfolio.imgUrl} alt="" />
                </figure>

                <div className='w-full h-full bg-primaryColor bg-opacity-40 absolute top-0 left-0 z-[5] hidden group-hover:block'>
                  <div className='w-full h-full flex items-center justify-center '>
                    <button onClick={() => ShowModalHandler(Portfolio.id)} className='text-white bg-headingColor hover:bg-smallTextColor py-2 px-4  rounded-[8px] font-[500] ease-in duration-200 '>See Details</button>
                  </div>
                </div>
              </div>
            ))}
          </div>


        </div>

        <div className='text-center mt-6 '>

          {
            nextItems < portfolios.length && data.length > 6 && (<button onClick={loadMoreHandler} className='text-white bg-headingColor hover:bg-smallTextColor py-2 px-4  rounded-[8px] font-[500] ease-in duration-200 '>Load More...</button>)
          }

        </div>

      </div>
      {
        ShowModal && <Modal setShowModel={setShowModal} activeID={activeID} />
      }
    </section>
  )
}

export default Portfolio