import React from "react"
// import { useEffect, useRef } from 'react'


function Footer() {
  // const headerRef = useRef(null)

  const year = new Date().getFullYear()

  // const stickyHeaderFunc = () => {
  //   window.addEventListener('scroll', () => {
  //     if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
  //       headerRef.current.classList.add('sticky_header');
  //     } else {
  //       headerRef.current.classList.remove('sticky_header');
  //     }
  //   });
  // };

  // useEffect(() => {
  //   stickyHeaderFunc();

  //   return window.removeEventListener('scroll', stickyHeaderFunc);
  // }, [])

  return (
    <footer className='bg-[#12141e] pt-12'>

      {/*============Footer Top */}
      <div className='container'>
        <div className='sm:flex items-center justify-between md:gap-8'>
          <div className='w-full sm:w-1/2'>
            <h2 className='text-[26px] leading-10 text-white font-[600] mb-5 md:text-[2rem]'>
              Do you Want To Make Beautiful Products?
            </h2>

            <a href='#contact'>
              <button className='bg-primaryColor text-white font-[500] flex items-center gap-2 hovering:bg-smallTextColor ease-in duration-300 py-2 px-4 rounded-[8px]'><i className="ri-mail-line"></i> Hire Me</button>
            </a>
          </div>

          <div className='w-full sm:w-1/2'>
            <p className='text-gray-300 leading-7 mt-4 sm:mt-0'>
              Advocate Maroga - Full-stack Developer with Cyber Security expertise, crafting innovative solutions to enhance user experience and protect digital assets. Let's Build something great together!
            </p>

            <div className='flex items-center gap-4 flex-wrap md-gap-9 mt-10'>
              <span className='text-gray-300 text-[15px] font-[600]'>
                Follow Me:
              </span>

              <span className='w-[35px] h-[35px] bg-[#2b2d33] p-1 rounded-[50px] text-[18px] font-[600] cursor-pointer text-center'>
                <a href='https://github.com/AdvocateM' className='text-gray-300 '>
                  <i className="ri-github-fill"></i>
                </a>
              </span>

              <span className='w-[35px] h-[35px] bg-[#2b2d33] p-1 rounded-[50px] text-[18px] font-[600] cursor-pointer text-center'>
                <a href='twitter.com/mr_maroga' className='text-gray-300  text-[18px] font-[500]'>
                  <i className="ri-twitter-line"></i>
                </a>
              </span>

              <span className='w-[35px] h-[35px] bg-[#2b2d33] p-1 rounded-[50px] text-[18px] font-[600] cursor-pointer text-center'>
                <a href='https://github.com/AdvocateM' className='text-gray-300  text-[18px] font-[500]'>
                  <i className="ri-instagram-fill"></i>
                </a>
              </span>

              <span className='w-[35px] h-[35px] bg-[#2b2d33] p-1 rounded-[50px] text-[18px] font-[600] cursor-pointer text-center'>
                <a href='https://www.linkedin.com/in/tshegofatso-advocate-m-5a9872140/' className='text-gray-300  text-[18px] font-[500]'>
                  <i className="ri-linkedin-box-fill"></i>
                </a>
              </span>

              <span className='w-[35px] h-[35px] bg-[#2b2d33] p-1 rounded-[50px] text-[18px] font-[600] cursor-pointer text-center'>
                <a href='https://www.codewars.com/users/AdvocateM/' className='text-gray-300  text-[18px] font-[500]'>
                  <i className="ri-code-line"></i>
                </a>
              </span>
            </div>

            <div className='flex items-center gap-4 flex-wrap md-gap-9 mt-10'>
              <span className='text-gray-300 text-[15px] font-[600]'>
                Call Me:
              </span>

              <span className='bg-[#2b2d33] p-1 text-[18px] font-[600] cursor-pointer'>
                <a href='https://github.com/AdvocateM' className='text-gray-300 '>
                  <i className="ri-customer-service-2-fill"></i> (+27) 12 051 3084
                </a>
              </span>

              <span className='bg-[#2b2d33] p-1 text-[18px] font-[600] cursor-pointer'>
                <a href='https://github.com/AdvocateM' className='text-gray-300'>
                  <i className="ri-phone-fill"></i>+27 71 442 8153
                </a>
              </span>


            </div>
          </div>
        </div>

        <div>
          <ul className="flex items-center justify-center gap-10 mt-10">
            <li><a className="text-gray-400 font-[600]" href="#about">About</a></li>
            <li><a className="text-gray-400 font-[600]" href="#service">Service</a></li>
            <li><a className="text-gray-400 font-[600]" href="#portfolio">Portfolio</a></li>
            <li><a className="text-gray-400 font-[600]" href="#contact">Contact</a></li>
          </ul>
        </div>

      </div>
      {/*============Footer Top  End*/}
      {/*============Footer Bottom*/}

      <div className='bg-[#1b1e29] py-5 mt-14'>
        <div className='container'>
          <div className='flex items-center justify-center sm:justify-between'>
            <div className='hidden sm:block'>
              <div className='flex items-center gap-[10px]'>
                <span className='w-[35px] h-[35px] rounded-full bg-[#2b2d33] text-white font-[500] text-[18px] flex  items-center justify-center'>Mr</span>

                <div className='leading-[20px]'>
                  <h2 className='text-gray-200 font-[500] text-[18px]'>Maroga</h2>
                  <p className="text-gray-400 text-[14px] font-[500]">Personal</p>
                </div>
              </div>

            </div>

            <div>
              <p className='text-gray-400 text-[14px]'> Copyright {year} by Mr_Maroga -All rights reserved </p>
            </div>
          </div>
        </div>
      </div>
      {/*============Footer bottom End*/}

    </footer>
  )
}

export default Footer
