import { useEffect } from "react";
import Aos from "aos";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga"
import "./App.css";

import Footer from "./Components/Footer/Footer"
import Header from "./Components/Header/Header"
import Hero from "./Components/UI/Hero";
import Service from "./Components/UI/Service";
import Portfolio from "./Components/UI/Portfolio";
import Contact from "./Components/UI/Contact";
import Products from "./Components/UI/Products";

// New Look
import Certificate from "./Components/UI/Certificate";
// import Phone from "./Components/UI/Phone"
import Solutions from "./Components/UI/web-solution"
import Cyber from "./Components/UI/cyber";

const Tracking_ID = "G-86BY3WDFVB"
ReactGA.initialize(Tracking_ID)

function App() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div>
      <Header />
      <main>
        <Hero />
        <Service />
        <Portfolio />
        {/* <Solutions /> */}
        <Products />
        {/* <Cyber /> */}
        {/* <Phone /> */}
        <Contact />
        <Certificate />
        <Footer />
      </main>
      <ToastContainer />
    </div>
  )
}

export default App;
