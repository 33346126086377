import React from 'react'
// import heroImg from '../../assets/images/Untitled.png'
import maroga from '../../assets/images/maroga.JPG'
import CountUp from 'react-countup';

const Hero = () => {
  const thisYear = new Date().getFullYear()
  const year = thisYear - 2017

  // console.log(year - 2017)

  return (
    <section className='pt-0' id='about'>
      <div className='container pt-14'>
        <div className='md:flex items-center justify-between sm:flex-col md:flex-row'>
          {/*=============Hero Left content ============== */}

          <div className='w-full md:basis-1/2'>
            <h5 data-aos='fade-right' data-aos-duration='1500' className='text-headingColor font-[600] text-[16px]'>Hello Welcome</h5>
            <h1 data-aos='fade-up' data-aos-duration='1500' className="text-headingColor text-[1.8rem] font-[800] sm:text-[40px] leading-[35px] sm:leading-[46px] mt-5">I'm Advocate Maroga <br />Full-Stack Developer</h1>;

            <div data-aos='fade-up' data-aos-duration='1800' data-aos-delay="200" className='flex items-center gap-6 mt-7'>
              <a href='#contact'>
                <button className='bg-primaryColor text-white font-[500] flex items-center gap-2 hovering:bg-smallTextColor ease-in duration-300 py-2 px-4 rounded-[8px]'><i className="ri-mail-line"></i> Hire Me</button>
              </a>

              <a href='#contact' className='text-smallTextColor font-[600] text-[16px] border-solid border-smallTextColor'>
                Check My Portfolio
              </a>


            </div>

            <p data-aos='fade-left' data-aos-duration='1500' className='flex gap-2 text-headingColor mt-14 font-[500] text-[15px] leading-7 sm:pl-14 sm:pr-10'>
              <span><i className="ri-apps-line"></i></span>
              Advocate Maroga is a Full-stack Developer with extensive experience in cyber security. With a passion for developing websites and photography as a hobby, I have worked on numerous projects utilizing a variety of languages, frameworks, and tools. As a Full-stack Developer, I am proficient in both Front-end and Back-end development and always strive to create clean, efficient, and scalable code.</p>

            <div className='flex items-center gap-6 mt-14'>
              <span className='text-smallTextColor text-[15] font-[600]'>
                Follow Me:
              </span>

              <span>
                <a href='https://github.com/AdvocateM' className='text-smallTextColor text-[18px] font-[600]'>
                  <i className="ri-github-fill"></i>
                </a>
              </span>

              <span>
                <a href='twitter.com/mr_maroga' className='text-smallTextColor text-[18px] font-[600]'>
                  <i className="ri-twitter-fill"></i>
                </a>
              </span>

              <span>
                <a href='https://github.com/AdvocateM' className='text-smallTextColor text-[18px] font-[600]'>
                  <i className="ri-instagram-fill"></i>
                </a>
              </span>

              <span>
                <a href='https://www.linkedin.com/in/advocate-m-5a9872140/' className='text-smallTextColor text-[18px] font-[600]'>
                  <i className="ri-linkedin-box-fill"></i>
                </a>
              </span>

              <span>
                <a href='https://www.codewars.com/users/AdvocateM/' className='text-smallTextColor text-[18px] font-[600]'>
                  <i className="ri-code-line"></i>
                </a>
              </span>


            </div>
          </div>
          {/*=============Hero Left End ============== */}
          {/*=============Hero Img ============== */}
          <div className='basis-1/3 mt-10 sm:mt-0'>
            <figure className='flex items-center justify-center'>
              <img src={maroga} alt='' />
            </figure>
          </div>
          {/*=============Hero Img end============== */}
        {/*
          <div className='md:basis-1/5 flex justify-between text-center mt-10 flex-wrap gap-3 md:mt-0 md:flex-col md:justify-end md:text-end'>
            <div className='mb-10'>
              <h2 className='text-headingColor font-[700] text-[32px]'>
                <CountUp start={0} end={year} duration={2} suffix='+' />
              </h2>

              <h4 className='text-headingColor font-[600] text-[18px]'>
                Coding Experience
              </h4>
            </div>

            <div className='mb-10'>
              <h2 className='text-headingColor font-[700] text-[32px]'>
                <CountUp start={0} end={78} duration={2} suffix='%' />
              </h2>

              <h4 className='text-headingColor font-[600] text-[18px]'>
                Success Rate
              </h4>
            </div>

            <div className='mb-10'>
              <h2 className='text-headingColor font-[700] text-[32px]'>
                <CountUp start={0} end={18} duration={2} suffix='+' />
              </h2>

              <h4 className='text-headingColor font-[600] text-[18px]'>
                Happy Clients
              </h4>
            </div>

            <div className='mb-10'>
              <h2 className='text-headingColor font-[700] text-[32px]'>
                <CountUp start={0} end={127} duration={2} suffix='+' />
              </h2>

              <h4 className='text-headingColor font-[600] text-[18px]'>
                Projects Completed
              </h4>
            </div>
          </div> */}
          {/*=============Hero Content Right ============== */}
          {/*=============Hero Content Right End============== */}
        </div>
      </div>
    </section>
  )
}

export default Hero
