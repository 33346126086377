import React, { useState } from 'react'
import Hire from './Hire'
import { toast } from 'react-toastify'
// import Spinner from "../Spinner"
import { db } from '../../firebaseConfig.js'
import { serverTimestamp, collection, addDoc, setDoc } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';

const Register = ({ setShowModel }) => {
  const [log, setLog] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [phone, setPhone] = useState('')
  const [inquiry, setInquiry] = useState('')

  const ShowLog = () => {
    setLog(true)
  }

  const myForm = { name: name, email: email, phone: phone, password: password, timestamp: serverTimestamp() }

  const contactRef = collection(db, 'user');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    const re_phone = /^\(?\d{3}\)?[-. ]?\d{3}[-. ]?\d{4}$/;
    const re_email = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    const re_name = /^[a-zA-Z]{2,10}\s[a-zA-Z]{2,10}$/;
    // const re_password = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!re_name.test(name) && !re_email.test(email) && !re_phone.test(phone)) {
      if (!re_name.test(name)) {
        toast.error("Please enter a valid Full Name")
      }

      if (!re_email.test(email)) {
        toast.error("Please enter a valid Email")
      }

      if (!re_phone.test(phone)) {
        toast.error("Please enter a valid phone number")
      }

      // if (!re_password.test(password)) {
      //   toast.error("Please enter Strong Passwords")
      //   toast.error("At least 8 characters long")
      //   toast.error("Contains at least 1 uppercase letter")
      //   toast.error("Contains at least 1 lowercase letter")
      //   toast.error("Contains at least 1 number")
      //   toast.error("Contains at least 1 special character (@$!%*?&)")
      // }
    } else {
      try {
        // // Get Reference

        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        // console.log('Successfully created user:', userCredential.user.uid);


        // Firestore database
        const contactRef = collection(db, 'contact');
        const myData = {
          name: name,
          email: email,
          phone: phone,
          password: password,
          created_at: serverTimestamp(),
          user_id: userCredential.user.uid // Add user ID as a reference
        };
        await addDoc(contactRef, myData);

        toast.success('Successfully added document:', myData);
        // Clear form
        setName('');
        setEmail('');
        setPassword('');
        setPhone('')
        // console.log(name, email, phone, message);
        toast.success('Your message has been submitted👍,');

        // setLoader(false);
      } catch (error) {
        toast.error('Could Not Sent Your Message 😫');
        // setLoader(false);
      }
    }

  };



  return (
    <div className='w-full h-full fixed top-10 left-0 z-10 bg-headingColor bg-opacity-40'>
      <div className='w-11/12 md:max-w-[600px] md:w-full absolute top-1/2 left-1/2 z-20 bg-gray-800 rounded-[8px] transform -translate-x-1/2 -translate-y-1/2 p-5'>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Please Call Me
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>

              <div>
                <label for="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Full Name</label>
                <input type="text" onChange={(e) => setName(e.target.value)} value={name} name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Advocate Maroga" required="" />
              </div>

              <div>
                <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                <input type="email" onChange={(e) => setEmail(e.target.value)} value={email} name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required="" />
              </div>

              <div>
                <label for="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your phone</label>
                <input type="tel" name="phone" id="phone" onChange={(e) => setPhone(e.target.value)} value={phone} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter whatsapp No: +27 71 442 8153" required="" />
              </div>

              <div>
                <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Inquery</label>
                <input type="text" name="password" id="inquery" onChange={(e) => setPassword(e.target.value)} value={password} placeholder="I want an account or i want to work with u" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                  </div>
                  <div className="ml-3 text-sm">
                    <label for="remember" className="text-gray-500 dark:text-gray-300">Do u want to receive a call?</label>
                  </div>
                </div>

              </div>
              <button type="submit" className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Create</button>
              <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                Already in the 'appy app? <a href="https://wa.me/27714428153?text=I'm%20inquiring%20About%20Hiring%20Your%20Services" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Whatsapp Me!</a>
              </p>
            </form>
          </div>
        </div>

        <button onClick={() => setShowModel(false)} className='w-[1.8rem] h-[1.8rem] bg-[white] absolute top-[1.7rem] right-[1.7rem] text-[25px] flex items-center justify-center rounded-[3px] leading-0 cursor-pointer hover:bg-smallTextColor hover:text-white hover:font-[500px]'><i className="ri-close-line"></i></button>
      </div>
      {
        log && <Hire setShowModel={setLog} />
      }
    </div>
  )
}

export default Register