import { useState } from "react"
import React from 'react'
import { toast } from 'react-toastify'
import Spinner from "../Spinner"
import { db } from '../../firebaseConfig.js'
import { serverTimestamp, collection, addDoc } from "firebase/firestore";

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  // eslint-disable-next-line 
  const [loader, setLoader] = useState(true);

  const myData = { name: name, email: email, phone: phone, message: message, timestamp: serverTimestamp() };
  // const contactRef = collection(db, 'contacts');

  const contactRef = collection(db, 'contact');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const re_phone = /^\(?\d{3}\)?[-. ]?\d{3}[-. ]?\d{4}$/;
    const re_email = /^([a-zA-Z0-9_-]+)@([a-zA-Z0-9_-]+)\.([a-zA-Z]{2,5})$/;
    const re_name = /^[a-zA-Z]{2,10}\s[a-zA-Z]{2,10}$/;
    const re_message = /^[\w\s]{1,200}$/;

    if (!re_name.test(name) && !re_email.test(email) && !re_phone.test(phone) && !re_message.test(message)) {
      if (!re_name.test(name)) {
        toast.error("Please enter a valid Full Name")
      }

      if (!re_email.test(email)) {
        toast.error("Please enter a valid Email")
      }

      if (!re_phone.test(phone)) {
        toast.error("Please enter a valid phone number")
      }

      if (!re_message.test(message)) {
        toast.error("Please enter a valid Message")
      }
    } else {
      try {
        // // Get Reference

        await addDoc(contactRef, myData);
        console.log('Successfully logged in your profile')
        // console.log(name, email, phone, message);

        // Clear form
        setName('');
        setEmail('');
        setMessage('');
        setPhone('')
        // console.log(name, email, phone, message);
        toast.success('Your message has been submitted👍,');

        setLoader(false);
      } catch (error) {
        toast.error('Could Not Sent Your Message 😫');
        setLoader(false);
      }
    }

  };


  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   addDoc(contactRef, myData);
  //   console.log(myData)
  //   toast.success('Your message has been submitted👍');
  // }


  return (
    <section id='contact' className='pb-16'>
      <div className='container'>
        <h2 className='text-headingColor font-[700] text-[2.5rem] mb-8' >Get In Touch</h2>
        <div className='md:flex justify-between items-center'>
          <div className='w-full md:w-1/2 h-[300px] sm:h-[450px] '>
            <iframe title="google-maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14513.343233023417!2d30.144621856461367!3d-24.57760445402557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ec17fc38fcbe357%3A0xd10c54da09b40b6f!2sDriekop%2C%201129!5e0!3m2!1sen!2sza!4v1680920538091!5m2!1sen!2sza" className='boarder-0 w-full h-full' allowFullScreen="" loading={<Spinner />} referrerPolicy="no-referrer-when-downgrade"></iframe>

          </div>
          <div className='w-full mt-8 md:mt-0 md:w-1/2 sm:h-[450px] lg:flex items-center bg-indigo-100 px-4 lg:px-8 py-8 '>
            <form className='w-full' onSubmit={handleSubmit}>
              <div className='mb-5'>
                <input onChange={(e) => setName(e.target.value)} value={name} type="text" placeholder='Enter Your Name' className='w-full p-3 focus:outline-none rounded-[5px]' />
              </div>

              <div className='mb-5'>
                <input onChange={(e) => setEmail(e.target.value)} value={email} type="text" placeholder='Enter Your Email' className='w-full p-3 focus:outline-none rounded-[5px]' />
              </div>

              <div className='mb-5'>
                <input onChange={(e) => setPhone(e.target.value)} value={phone} type="text" placeholder='Enter Your Phone' className='w-full p-3 focus:outline-none rounded-[5px]' />
              </div>



              <div className='mb-5'>
                <textarea onChange={(e) => setMessage(e.target.value)} value={message} type="text" placeholder='Write Your Message...' className='w-full p-3 focus:outline-none rounded-[5px]' />
              </div>

              <button type='submit' className='w-full p-3 focus:outline-none rounded-[5px] bg:smallTextColor text-black hover:bg-headingColor hover:text-white text-center ease-liner duration-150'>Send Message</button>


            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact