import React from 'react'
import frontendImg from '../../assets/images/front-end.png'
import backendImg from '../../assets/images/backend.png'
import uiImd from '../../assets/images/design.png'
import appsImg from '../../assets/images/apps.png'

const Service = () => {
  return (
    <section id='services'>
      <div className='container lg:pt-5'>
        <div className='text-center'>
          <h2 className='text-headingColor font-[800] text-[2.4rem] mb-5'>What Do I Do </h2>

          <p className='lg:max-w-[600px] lg:mx-auto text-headingColor font-[500] text-[16px] leading-7'> I provide a range of services including front-end and full-stack development, back-end development, app development, and cyber security solutions.Whether you need a website built from scratch, an app developed for your business, or want to ensure your digital assets are secure, I have the expertise to deliver high-quality results. </p>

        </div>

        <div className='flex flex-col justify-center sm:py-12'>
          <div className='w-full py-3 px-2  sm:max-w-xl sm:mx-auto sm:px-0'>
            <div className='relative text-gray-700 antialiased text-sm font-semibold'>
              {/*==============Vertical Line running through the middle */}

              <div className='hidden absolute w-1 sm:block bg-indigo-300 h-full left-1/2 transform-translate-x-1/2'>
              </div>
              {/* =================Left Card 1*/}
              <div className='mt-6 sm:mt-0 sm:mb-12'>
                <div className='flex items-center flex-col sm:flex-row'>
                  <div className='flex justify-start w-full mx-auto items-center'>
                    <div className='w-full sm:w-1/2 sm:pr-8'>
                      <div data-aos="fade-right" data-aos-duration="1200" className='bg-white p-4 rounded shadow group hover:bg-primaryColor curse-pointer ease-in duration-150'>
                        <h3 className='text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl'>Front-End Development</h3>

                        <p className='text-[15px] text-smallTextColor group-hover:text-white group-hover:text-white group-hover:font-[500] leading-7'>

                          Building beautiful user interface with HTML, CSS and Javascript using frameworks such as React Js and Vanilla
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='rounded-full bg-primaryColor border-white boarder-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center'>
                    <figure>
                      <img src={frontendImg} alt='' />
                    </figure>
                  </div>
                </div>
              </div>

              {/* =================Right Card 1 */}
              <div className='mt-6 sm:mt-0 sm:mb-12'>
                <div className='flex items-center flex-col sm:flex-row'>
                  <div className='flex justify-end w-full mx-auto items-center'>
                    <div className='w-full sm:w-1/2 sm:pl-8'>
                      <div data-aos="fade-left" data-aos-delay='50' data-aos-duration="1300" className='bg-white p-4 rounded shadow group hover:bg-primaryColor curse-pointer ease-in duration-150'>
                        <h3 className='text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl'>Back-End Development</h3>

                        <p className='text-[15px] text-smallTextColor group-hover:text-white group-hover:text-white group-hover:font-[500] leading-7'>

                          Back-end Development: working on server-side of a websites and application, utilizing technologies such as Node.js, Express.js, MongoDB and PostgreSQL.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='rounded-full bg-primaryColor border-white boarder-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center'>
                    <figure>
                      <img src={backendImg} alt='' />
                    </figure>
                  </div>
                </div>
              </div>

              {/* =================Left Card 2*/}
              <div className='mt-6 sm:mt-0 sm:mb-12'>
                <div className='flex items-center flex-col sm:flex-row'>
                  <div className='flex justify-start w-full mx-auto items-center'>
                    <div className='w-full sm:w-1/2 sm:pr-8'>
                      <div data-aos="fade-left" data-aos-delay='100' data-aos-duration="1400" className='bg-white p-4 rounded shadow group hover:bg-primaryColor curse-pointer ease-in duration-150'>
                        <h3 className='text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl'>App Development</h3>

                        <p className='text-[15px] text-smallTextColor group-hover:text-white group-hover:text-white group-hover:font-[500] leading-7'>

                          Creating powerful and responsive mobile applications for ios and Android
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='rounded-full bg-primaryColor border-white boarder-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center'>
                    <figure>
                      <img src={uiImd} alt='' />
                    </figure>
                  </div>
                </div>
              </div>

              {/* =================Right Card 2*/}
              <div className='mt-6 sm:mt-0 sm:mb-12'>
                <div className='flex items-center flex-col sm:flex-row'>
                  <div className='flex justify-end w-full mx-auto items-center'>
                    <div className='w-full sm:w-1/2 sm:pl-8'>
                      <div data-aos="fade-left" data-aos-duration="1500" className='bg-white p-4 rounded shadow group hover:bg-primaryColor curse-pointer ease-in duration-150'>
                        <h3 className='text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl'>Cyber Security</h3>

                        <p className='text-[15px] text-smallTextColor group-hover:text-white group-hover:text-white group-hover:font-[500] leading-7'>

                          Providing comprehensive solutions to protect your digital assets from unauthorized access and damage
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='rounded-full bg-primaryColor border-white boarder-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center'>
                    <figure>
                      <img src={appsImg} alt='' />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Service
