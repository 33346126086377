import React, { useEffect, useRef, useState } from 'react'
// import Hire from '../UI/Hire';
import Register from '../UI/Register';


function Header() {
  const headerRef = useRef(null)
  const menuRef = useRef(null)
  const [ShowModal, setShowModal] = useState(false)

  const stickyHeaderFunc = () => {
    window.addEventListener('scroll', () => {
      if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
        headerRef.current.classList.add('sticky_header');
      } else {
        headerRef.current.classList.remove('sticky_header');
      }
    });
  };

  const handleClick = e => {
    e.preventDefault()

    const targetAttr = e.target.getAttribute("href")
    console.log(targetAttr)
    const location = document.querySelector(targetAttr).offsetTop

    window.scrollTo({
      top: location - 80,
      left: 0,
    })
  }

  const toggleMenu = () => menuRef.current.classList.toggle('show__menu')

  const ShowModalHandler = () => {
    setShowModal(true)
  }


  useEffect(() => {
    stickyHeaderFunc();

    return window.removeEventListener('scroll', stickyHeaderFunc);
  }, [])

  return (
    <header ref={headerRef} className="w-full h-[80px] leading-[80px] flex items-center">
      <div className="container">
        <div className="flex items-center justify-between">
          {/*========Logo=====*/}

          <div className="flex items-center gap-[10px]">
            {/* <span className="w-[35px] h-[35px] bg-primaryColor text-white text-[18px] font-[500] rounded-full flex items-center justify-center">Mr</span> */}

            <div className="leading-[13px]">
              <h2 onClick={handleClick} className="text-xl text-smallTextColor font-[700]  font-TANPearl" href="#about">Mr_Maroga</h2>
              {/* <p className="text-smallTextColor text-[16px] font-[500]">Personal</p> */}
            </div>
          </div>

          {/*=======Logo end========*/}

          {/*========Menu Start=========== */}

          <div ref={menuRef} className="menu">
            <ul className="flex items-center gap-10">
              <li><a onClick={handleClick} className="text-smallTextColor font-[600]" href="#about">About</a></li>
              <li><a onClick={handleClick} className="text-smallTextColor font-[600]" href="#services">Service</a></li>
              <li><a onClick={handleClick} className="text-smallTextColor font-[600]" href="#portfolio">Portfolio</a></li>
              {/* <li><a onClick={handleClick} className="text-smallTextColor font-[600]" href="#cyber">Cyber</a></li> */}
              {/* <li><a onClick={handleClick} className="text-smallTextColor font-[600]" href="#website">Websites</a></li> */}
              {/* <li><a onClick={handleClick} className="text-smallTextColor font-[600]" href="#phone">Landline</a></li> */}
              <li><a onClick={handleClick} className="text-smallTextColor font-[600]" href="#contact">Contact</a></li>
              <li><a onClick={handleClick} className="text-smallTextColor font-[600]" href="#certificate">Courses</a></li>
            </ul>
          </div>
          {/*==================Menu End ===========*/}

          {/*===================Menu Right ================ */}
          <div className="flex items-center gap-4">
            <button onClick={() => ShowModalHandler()} className="flex items-center gap-2 text-smallTextColor font-[600] border border-solid border-smallTextColor py-2 px-4 rounded-[8px] max-h-[40px] hover:bg-smallTextColor hover:text-white hover:font-[500px] ease-in duration-300">
              <i className="ri-send-plane-fill"></i> Let's Talk
            </button>

            <span onClick={toggleMenu} className="text-2xl text-smallTextColor md:hidden curser-pointer"><i className="ri-menu-line"></i></span>
          </div>
        </div>
        {
          ShowModal && <Register setShowModel={setShowModal} />
        }
      </div>

    </header>
  )
}

export default Header; 