import React from 'react'
import portfolios from '../../assets/data/portfolioData'

const Modal = ({ activeID, setShowModel }) => {


  const portfolio = portfolios.find(portfolio => portfolio.id === activeID)
  return (
    <div className='w-full h-full fixed top-10 left-0 z-10 bg-headingColor bg-opacity-40'>
      <div className='w-11/12 md:max-w-[600px] md:w-full absolute top-1/2 left-1/2 z-20 bg-white rounded-[8px] transform -translate-x-1/2 -translate-y-1/2 p-5'>
        <div>
          <img className='rounded-[8px]' src={portfolio.imgUrl} alt='' />
        </div>


        <div>
          <h2 className='text-xl text-headingColor font-[700] my-5'>{portfolio.title}</h2>

          <p className='text-[15px] leading-7 text-smallTextColor'>{portfolio.description}</p>

          <div className='mt-5 flex items-center gap-3 flex-wrap '>
            <h4 className='text-headingColor text-[18px] text-[700]'>Technologies:</h4>

            {
              portfolio.technologies.map((items, index) => <span key={index} className="bg-gray-200 py-1 px-2 rounded-[5] text-[14px] leading-0">{items}</span>)
            }
          </div>


          <div className='mt-5 flex items-center gap-3 flex-wrap'>
            <a href={portfolio.siteUrl}>
              <button className='bg-primaryColor text-white py-2 px-4 my-8 rounded-[8px] font-[500] hover:bg-headingColor eas-in duration-300'>Live Site</button>
            </a>

            <a href={portfolio.github}>
              <button className='bg-primaryColor text-white py-2 px-4 my-8 rounded-[8px] font-[500] hover:bg-headingColor eas-in duration-300'>Github</button>
            </a>
          </div>
        </div>

        <button onClick={() => setShowModel(false)} className='w-[1.8rem] h-[1.8rem] bg-[white] absolute top-[1.7rem] right-[1.7rem] text-[25px] flex items-center justify-center rounded-[3px] leading-0 cursor-pointer'><i className="ri-close-line"></i></button>
      </div>
    </div>
  )
}

export default Modal