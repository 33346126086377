import elison from "../.../../../assets/logo/alison-free-courses.svg"
import cyf from "../.../../../assets/logo/logo.png"
import cyber from "../.../../../assets/logo/cybrary_logo_black.svg"
import udemy from "../.../../../assets/logo/udemy-logo.png"
import solo from "../.../../../assets/logo/image.png"
// import codew from "../.../../../assets/logo/logo-f607a0fb.svg"

export default function Certificate() {
  return (
    <section id='certificate'>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
            Certificate with One of the Best
          </h2>
          <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src={cyf}
              alt="Transistor"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src={elison}
              alt="Reform"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src={cyber}
              alt="Tuple"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
              src={udemy}
              alt="SavvyCal"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
              src={solo}
              alt="Statamic"
              width={158}
              height={48}
            />


          </div>
        </div>
      </div>
    </section>
  )
}