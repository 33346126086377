// import portfolioImg01 from "../images/portfolio-01.jpg";
// import portfolioImg02 from "../images/portfolio-02.jpg";
// import portfolioImg03 from "../images/portfolio-03.jpg";
// import portfolioImg04 from "../images/portfolio-04.jpg";
// import portfolioImg05 from "../images/portfolio-05.jpg";
// import portfolioImg06 from "../images/portfolio-06.jpg";
// import portfolioImg07 from "../images/portfolio-07.jpg";
import github from "../images/github-finder.png";
// import morubetsi from "../images/morubetsi.png";
import edufocus from "../data/edufocus.png"
import ken from "../data/Ken.png"
// import babe from "../data/babe.png"
import Karabo from "../data/karabo.png"
// import weather from "../data/weather.png"
// import Karma from "../data/karma.png"
// import new_grid from "../data/new_grid.png"
import edge from "../data/edge.png"
// import knowledge from "../data/knowledge.png"
// import Bike from "../data/Bike.png"
// import Cake from "../data/Cake.png"
// import Burger from "../data/burger.png"
// import vshotel from "../data/vshotel.png"
import netflix from "../data/netflix.png"
import maroga from "../data/maroga.png"
import mtjt from "../data/MTJT.png"
import craftbakez from "../data/craftbakezScrre.png";

const portfolios = [
  {
    id: "01",
    imgUrl: "https://raw.githubusercontent.com/AdvocateM/Job-listings-with-filtering/main/design/desktop-preview.jpg",
    category: "Vanilla JS",
    title: "Job Listing Website",
    description:
      "Our job listing website for developers connects talented professionals with top companies seeking skilled candidates. With a user-friendly interface, job seekers can easily browse listings, create profiles, and submit applications. Employers can post job openings, review resumes, and track applicant progress, making it an efficient and effective hiring solution. Join our community today and take the first step towards your dream career or your ideal hire.",
    technologies: ["HTML", "Css", "Javascript"],
    siteUrl: "https://job-listing-advocate-cyf.netlify.app/",
    github: 'https://github.com/AdvocateM/Job-listings-with-filtering'
  }, {
    id: "02",
    imgUrl: Karabo,
    category: "Vanilla JS",
    title: "Karabo Portfolio Website",
    description:
      "Create a captivating portfolio to showcase Karabo's skills and talents. Highlight his best work samples, emphasize his expertise, and include testimonials for added credibility. Provide clear contact information and design the portfolio with a professional and visually appealing layout. Update it regularly to stay relevant and make a lasting impression on potential clients or employers.",
    technologies: ["HTML", "Css", "Vanilla JS"],
    siteUrl: "https://karabo-maroga.netlify.app/",
    github: "https://github.com/AdvocateM/weather-App"
  },
  {
    id: "03",
    imgUrl: github,
    category: "vanilla JS",
    title: "Github Finder",
    description:
      "Github Finder is a web application designed to help developers search for Github users and repositories. With Github Finder, users can easily search for Github users by name and view their profiles, including their repositories, followers, and following. Additionally, users can search for repositories and view their details, including their forks, watchers, and open issues.!",
    technologies: ["Vanilla", "Bootstrap css", "Javascript", "HTML"],
    siteUrl: "https://github-finder-advocate.netlify.app/",
    github: "https://github.com/AdvocateM/Github-finder"
  },
  {
    id: "04",
    imgUrl: edufocus,
    category: "React JS",
    title: "Edufocus Project",
    description:
      "Enhancing holistic teacher wellbeing through sound praxis-oriented research and action-based intervention programmes",
    technologies: ["HTMl", "CSS", "Tailwind Css", "React JS"],
    siteUrl: "https://starter-kit-g0y0.onrender.com/",
    github: "#"
  },
  {
    id: "05",
    imgUrl: netflix,
    category: "Vanilla JS",
    title: "Netflix Landing page",
    description:
      "Designed a sleek and user-friendly landing page for Netflix. Experience seamless navigation and a clean, modern layout. Check out my coding skills in action!q",
    technologies: ["HTML", "CSS"],
    siteUrl: "https://netflixclonebyadvocate.netlify.app/",
    github: "https://github.com/AdvocateM/netflix"
  },
  {
    id: "06",
    imgUrl: ken,
    category: "Vanilla JS",
    title: "Ken MTC",
    description:
      "Crafted a dynamic photographer's website using vanilla JS, HTML, and CSS. Infused it with captivating animations for a visually appealing user experience. Explore my coding prowess in bringing creativity to life!",
    technologies: ["HTML", "CSS", "Javascript"],
    siteUrl: "https://kenomtcj.netlify.app",
    github: "https://github.com/AdvocateM/kenomtc"
  },
  {
    id: "07",
    imgUrl: edge,
    category: "Vanilla JS",
    title: "Financial Page",
    description:
      "This sample website for a financial management company is created using HTML5 and CSS3 with Flexbox, drawing inspiration from Brad Traversy's Udemy course, 'Modern HTML and CSS from the Beginning.' It's a simple yet effective representation of modern web development techniques. Check out the clean design and responsive layout tailored for a seamless user experience",
    technologies: ["Vanilla", 'HTML', "css", "javascript"],
    siteUrl: "https://app.netlify.com/sites/edgeledger5/",
    github: "https://github.com/AdvocateM/EdgeLedger"
  },
  {
    id: "08",
    imgUrl: maroga,
    category: "React Js",
    title: "Maroga Ecommerce",
    description:
      "Are you ready to elevate your online business to the next level? With our Full Stack Maroga Ecommerce Web App, you can now provide your customers with a seamless and delightful shopping experience from the comfort of their homes.",
    technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
    siteUrl: "https://maroga.co.za/",
    github: "https://github.com/AdvocateM/tv-show-dom-project"
  },
  {
    id: "09",
    imgUrl: craftbakez,
    category: "React JS",
    title: "Craftbakez",
    description:
      "where every bite is crafted with love experience homemade goodness, Delivered Fresh indulge in Bakery Bliss, Right at your doorstep from our oven to your table, enjoy the taste of tradition",
    technologies: ["React", "Tailwind css", "Node.js", "firebase"],
    siteUrl: "https://craftbakez.co.za",
    github: "https://github.com/AdvocateM/HTML-CSS-Module-Project"
  },
  {
    id: "10",
    imgUrl: mtjt ,
    category: "Vanilla JS",
    title: "MTJT Website",
    description:
      "MTJT is a dynamic website powered by EmailJS for seamless email communication. Deployed on cPanel, it ensures reliable access and high performance. With a sleek design and user-friendly interface, MTJT offers efficient services and a satisfying user experience.",
    technologies: ["React", "Node.js"],
    siteUrl: "https://mtjt.co.za/",
    github: "https://github.com/AdvocateM/MTJT",
  },
  // {
  //   id: "10",
  //   imgUrl: knowledge,
  //   category: "React JS",
  //   title: "File Sharing Website",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
  //   technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  //   siteUrl: "https://knowledge-resume2.netlify.app",
  //   github: "https://github.com/AdvocateM/knowledge_resume"
  // },
  // {
  //   id: "14",
  //   imgUrl: vshotel,
  //   category: "React JS",
  //   title: "Appointment Booking Website",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
  //   technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  //   siteUrl: "https://jocular-medovik-636a0a.netlify.app/",
  //   github: "https://github.com/AdvocateM/VIP-STAY-hotel"
  // },
  // {
  //   id: "12",
  //   imgUrl: Bike,
  //   category: "Vanilla JS",
  //   title: "Bike for Refugees",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
  //   technologies: ["Vanilla", "HTML", "Css", "javascript"],
  //   siteUrl: "https://animated-vacherin-16fb3e.netlify.app/",
  //   github: "https://github.com/AdvocateM/bikes-for-refugees"
  // },
  // {
  //   id: "13",
  //   imgUrl: Cake,
  //   category: "Vanilla JS",
  //   title: "Online Therapy Website",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
  //   technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  //   siteUrl: "https://responsive-cakes.netlify.app/",
  //   github: "https://github.com/AdvocateM/HTML-CSS-Coursework-Week3"
  // },

  // {
  //   id: "15",
  //   imgUrl: Burger,
  //   category: "React JS",
  //   title: "Appointment Booking Website",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
  //   technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  //   siteUrl: "https://hamburger-overlay-menu.netlify.app",
  //   github: "https://github.com/AdvocateM/Hamburger_overlay_menu"
  // },
 
];

export default portfolios;
