// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyC9alFMfTQd5pvK7uC9P2Cio2lbNfyKr_Q",
  authDomain: "my-cv-6a563.firebaseapp.com",
  databaseURL: "https://my-cv-6a563-default-rtdb.firebaseio.com",
  projectId: "my-cv-6a563",
  storageBucket: "my-cv-6a563.appspot.com",
  messagingSenderId: "336627719125",
  appId: "1:336627719125:web:e9184f8e0e8733b921fb5c",
  measurementId: "G-91K6BCKT1J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Get a Firestore instance
const db = getFirestore(app);


export { db, app, getAuth };

